<template>
    <div class="resend-verification-mail-page house-image" style="min-height: 100vh;">
        <div class="card" style="background-color: transparent; border: 0;">
            <div class="card-body py-0">
                <div class="card-title mb-3 title-bg-image">
                    <div class="logo-image">
                    </div>
                </div>
                <Form @submit="resend_verification_mail()" :validation-schema="schema">
                    <div class="card" style="border: 0; background-color: transparent;">
                        <div class="card-header h3 text-primary" style="background-color: transparent; border: 0;">
                            臺中購物節店家平台重發驗證信
                        </div>
                        <div class="card-body form-resend-verification-mail">
                            <div class="form-floating mb-3">
                                <Field name="email" v-slot="{ meta, field }" v-model="resend_verification_mail_form.email">
                                    <input
                                        id="email"
                                        v-bind="field"
                                        class="form-control"
                                        :class="{
                                            'is-valid': meta.valid && meta.touched,
                                            'is-invalid': !meta.valid && meta.touched,
                                        }"
                                        placeholder="email"
                                    />
                                </Field>
                                <label for="email">Email</label>
                                <div id="email_help" class="form-text text-start">請輸入Email</div>
                            </div>
                            <div class="row mb-3" style="padding: 0px 12px 0px 12px;">
                                <div class="px-0 col-6">
                                    <div class="input-group">
                                        <div class="form-floating">
                                            <Field name="captcha" v-slot="{ meta, field }" v-model="resend_verification_mail_form.captcha">
                                                <input
                                                    id="floatingCaptcha"
                                                    v-bind="field"
                                                    class="form-control"
                                                    :class="{
                                                        'is-valid': meta.valid && meta.touched,
                                                        'is-invalid': !meta.valid && meta.touched,
                                                    }"
                                                    type="captcha"
                                                    placeholder="帳號"
                                                />
                                            </Field>
                                            <label for="floatingCaptcha">驗證碼</label>
                                        </div>
                                <button type="button" class="input-group-text btn-primary btn" v-on:click="get_captcha()">
                                            <i class="bi bi-arrow-clockwise"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="px-0 col-6">
                                    <a @click="get_captcha" style="cursor: pointer;">
                                        <img :src="captcha_image" class="captcha-img">
                                    </a>
                                </div>
                            </div>

                            <button class="w-100 btn btn-lg btn-primary mb-3" type="submit">送出</button>

                            <div class="alert alert-success mb-3" role="alert" v-if="success_flag" style="white-space: pre-wrap;">
                                {{ success_message }}
                            </div>
                            <div class="alert alert-danger mb-3" role="alert" v-if="error_flag" style="white-space: pre-wrap;">
                                {{ error_message }}
                            </div>

                            <div class="mt-3 mb-3">
                                <span>您是臺中市的店家嗎？</span>
                            </div>
                            <div class="mb-3">
                                <span>歡迎踴躍加入本平台。</span>
                            </div>
                            <div class="mb-3">
                                <strong>
                                    <span><router-link style="text-decoration: none;" to="/Register">立即註冊</router-link></span>、
                                    <span><router-link style="text-decoration: none;" to="/Login">立即登入</router-link></span>
                                </strong>
                            </div>
                        </div>
                        <BeforeLoginFooter/>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
import { resend_verification_mail } from '../../api/auth';
import { captcha } from '../../api/captcha';
import { Field, Form } from 'vee-validate';
import { email_rules } from '../../rules/user/email';
import BeforeLoginFooter from "../../components/BeforeLoginFooter.vue";

export default {
    components: {
        Field,
        Form,
        BeforeLoginFooter,
    },
    data() {
        return {
            resend_verification_mail_form: {
                email: "",
                captcha: null,
                captcha_key: null,
            },
            success_message: "",
            success_flag: false,
            error_message: "",
            error_flag: false,
            captcha_image: "",
        }
    },
    methods: {
        resend_verification_mail() {
            this.error_flag = false;
            this.error_message = "",
            this.success_flag = false;
            this.success_message = "",
            resend_verification_mail(this.resend_verification_mail_form).then(response => {
                this.error_flag = false;
                this.error_message = "",
                this.success_message = response.data.message;
                this.success_flag = true;
            }, error => {
                this.success_flag = false;
                this.success_message = "",
                this.error_message = error.response.data.message;
                this.error_flag = true;
                this.get_captcha();
            });
        },
        get_captcha() {
            captcha().then(response => {
                this.captcha_image = response.data.img;
                this.resend_verification_mail_form.captcha_key = response.data.key;
            })
        },
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        this.get_captcha();
    },
    computed: {
        schema() {
            return email_rules;
        },
    }
};
</script>
